.paper-shelf-page {
  padding-left: 100px;
  padding-right: 50px;
  width: calc(100% - 150px);
  position: initial;
  height: 100%;
  overflow: auto;

  h1 {
    color: #ffd700;
    font-size: 53px;
    margin: 0;
    font-family: "Coolvetica", sans-serif;
    font-weight: 400;
    text-align: left;
    margin-bottom: 40px;
  }

  h1.page-title {
    margin-left: 100px;
    margin-top: 100px;
  }

  .content-zone {
    margin-bottom: 20px;
  }

  .content-sections {
    background-color: #115173;

    h3 {
      color: #dadada;
    }

    .expand-icon {
      color: #8d8d8d;
    }

    &:hover {
      background-color: #61dafb;

      h3 {
        color: #181818;
      }

      .expand-icon {
        color: #181818;
      }
    }
  }

  .content-details {
    background-color: #2f7dab;
  }
}

.images-container {
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
  padding: 20px 0;
}

.image-box {
  position: relative;
  flex: 1 1 20%;
  height: 300px;
  overflow: hidden;
  border-radius: 10px;
  max-width: calc(33.3% - 10px);
  background: linear-gradient(180deg, #153979, #000);

  .entity-image {
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    object-fit: contain;
    left: 0;
  }

  .content {
    position: absolute;
    width: calc(100% - 40px);
    z-index: 3;
    padding: 10px 20px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0, rgba(0, 0, 0, 1));
    bottom: -70px;
  }

  .title {
    margin-bottom: 0;
    margin-top: 0;
    color: #fff;
    font-size: 24px;
    font-weight: 500;
    line-height: 28px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .description {
    font-size: 14px;
    margin-bottom: 5px;
    color: #fff;
    font-weight: 700;
  }

  .read-date {
    position: absolute;
    z-index: 2;
    color: #312f2f;
    font-size: 12px;
    font-weight: 600;
    background: linear-gradient(to bottom right, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.3));
    border-radius: 10px;
    margin: 10px auto 0 10px;
    padding: 5px 10px;
    line-height: 0;
    backdrop-filter: blur(1rem) saturate(160%)
  }

  .authors {
    font-size: 14px;
    margin-bottom: 5px;
    color: #fff;
    font-weight: 700;
  }

  .btn {
    margin-top: 30px;
    margin-bottom: 10px;
    padding: 0 23px;
    height: 40px;
    line-height: 34px;
    border: 2px solid #ffd700;
    border-radius: 4px;
    font-size: 14px;
    color: #fff;
    background: transparent;
    text-transform: uppercase;
    font-weight: 700;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    cursor: pointer;
  }

  .btn:hover {
    transform: translateY(-3px);
    background: #ffd700;
  }

  &:after {
    content: "";
    background: linear-gradient(180deg, #ffd700, #000);
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    opacity: 0;
  }

  &:hover:after {
    opacity: 0.85;
  }

  &:hover .content {
    bottom: 0;
    background: transparent;
  }

  &:hover .title {
    white-space: initial;
    overflow: visible;
  }
}

.action-section {
  ul {
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: flex-start;

    li {
      padding: 0;
      list-style: none;
      //margin: 0 0 10px;
      overflow: hidden;
      display: block;
      position: relative;
      clear: both;
    }
  }

  .active-action-button {
    background: #ffd700;
  }
}

.action-button {
  margin: 10px;
  padding: 0 23px;
  height: 40px;
  line-height: 34px;
  border: 2px solid #ffd700;
  border-radius: 10px;
  font-size: 14px;
  color: #fff;
  background: transparent;
  text-transform: uppercase;
  font-weight: 700;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  cursor: pointer;
  box-shadow: 3px 3px 3px #4d4d4e;

  &:hover {
    background: #ffd700;
  }
}

@media screen and (max-width: 1200px) {
  .image-box {
    flex: 1 1 30% !important;
  }
}

@media screen and (max-width: 800px) {
  .image-box {
    flex: 1 1 40% !important;
  }
}
