.art-gallery-page {
  padding-left: 100px;
  padding-right: 50px;
  width: calc(100% - 150px);
  position: initial;
  height: 100%;
  overflow: auto;

  h1 {
    color: #ffd700;
    font-size: 53px;
    margin: 0;
    font-family: "Coolvetica", sans-serif;
    font-weight: 400;
    text-align: left;
    margin-bottom: 40px;
  }

  h1.page-title {
    margin-left: 100px;
    margin-top: 100px;
  }

  .images-container {
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
    padding-bottom: 100px;
  }

  .image-box {
    position: relative;
    flex: 1 1 20%;
    height: 400px;
    overflow: hidden;
    border-radius: 10px;
    max-width: calc(25% - 10px);

    .art-image {
      position: absolute;
      z-index: 2;
      width: 100%;
      height: 100%;
      object-fit: cover;
      left: 0;
    }

    .content {
      position: absolute;
      width: calc(100% - 40px);
      z-index: 3;
      padding: 10px 20px;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.2) 0,
        rgba(0, 0, 0, 1)
      );
      bottom: -70px;
    }

    .title {
      margin-bottom: 0;
      margin-top: 0;
      color: #fff;
      font-size: 24px;
      font-weight: 500;
      line-height: 28px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .description {
      font-size: 14px;
      margin-bottom: 5px;
      color: #fff;
      font-weight: 700;
    }

    .image-date {
      position: absolute;
      z-index: 2;
      color: #312f2f;
      font-size: 12px;
      font-weight: 600;
      background: linear-gradient(to bottom right, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.3));
      border-radius: 10px;
      margin: 10px auto 0 10px;
      padding: 5px 10px;
      line-height: 0;
      backdrop-filter: blur(1rem) saturate(160%)
    }

    .btn {
      margin-top: 30px;
      margin-bottom: 10px;
      padding: 0 23px;
      height: 40px;
      line-height: 34px;
      border: 2px solid #ffd700;
      border-radius: 4px;
      font-size: 14px;
      color: #fff;
      background: transparent;
      text-transform: uppercase;
      font-weight: 700;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      cursor: pointer;
    }

    .btn:hover {
      transform: translateY(-3px);
      background: #ffd700;
    }

    &:after {
      content: "";
      background: linear-gradient(180deg, #ffd700, #000);
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      opacity: 0;
    }

    &:hover:after {
      opacity: 0.85;
    }

    &:hover .content {
      bottom: 0;
      background: transparent;
    }

    &:hover .title {
      white-space: initial;
      overflow: visible;
    }
  }
}

.popup-disabled,
.tooltip-disabled {
  display: none;
}

.art-popup {
  position: fixed;
  width: calc(90% - 150px);
  height: 90vh;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #edefef;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.close-popup-icon {
  display: block;
  position: absolute;
  top: 15px;
  right: 15px;

  &:hover {
    color: #cc0707;
  }
}

.blur-background {
  backdrop-filter: blur(2px);
}

.art-name {
  display: none;
}

.arts-section {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.art-full-popup {
  height: 80vh;
  border: 5px solid #181818;
  max-width: calc(85% - 150px);
}

@media screen and (max-width: 1200px) {
  .image-box {
    flex: 1 1 30% !important;
  }

  .art-popup {
    height: max-content;
    width: 70vw;
  }

  .arts-section {
    height: max-content;
  }

  .art-full-popup {
    max-height: 48vh;
    max-width: 68vw;
    height: auto;
    width: auto;
  }
}

@media screen and (max-width: 800px) {
  .image-box {
    flex: 1 1 40% !important;
  }
}
