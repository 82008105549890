.page {
  width: 100%;
  height: 100%;
  position: absolute;
}

.container {
  width: 100%;
  will-change: contents;
  height: 90%;
  min-height: 566px;
  position: absolute;
  opacity: 0;
  top: 5%;
  margin: 0 auto;
  z-index: 1;
  transform-style: preserve-3d;
  animation: fadeIn 1s forwards;
  animation-delay: 1s;
}

.container.about-page,
.container.contact-page,
.container.skills-page,
.container.career-page,
.container.paper-shelf-page,
.container.art-gallery-page {
  .text-zone {
    position: absolute;
    left: 10%;
    top: 50%;
    transform: translateY(-50%);
    width: 35%;
    vertical-align: middle;
    display: table-cell;
    max-height: 90%;
    text-align: left;

    h1 {
      font-size: 53px;
      font-family: "Coolvetica", "sans-serif";
      color: #ffd700;
      font-weight: 400;
      margin-top: 0;
      position: relative;
      margin-bottom: 40px;
      left: 10px;
    }

    p {
      font-size: 15px;
      color: #fff;
      font-family: sans-serif;
      font-weight: 300;
      min-width: fit-content;
      animation: pulse 1s;

      &:nth-of-type(1) {
        animation-delay: 1.1s;
      }

      &:nth-of-type(2) {
        animation-delay: 1.2s;
      }

      &:nth-of-type(3) {
        animation-delay: 1.3s;
      }
    }

    .standout-text {
      font-size: 20px;
      font-weight: 400;
      color: #ffd700;
    }
  }

  .text-animate-hover {
    &:hover {
      color: #fff;
    }
  }
}

@media screen and (max-width: 1200px) {
  .page {
    position: initial;
  }
  .container {
    position: initial;
    height: auto;
    min-height: auto;

    &.about-page,
    &.contact-page,
    &.skills-page,
    &.career-page,
    &.paper-shelf-page,
    &.art-gallery-page {
      .text-zone {
        position: initial;
        transform: none;
        width: 100%;
        display: block;
        padding: 20px;
        box-sizing: border-box;
      }
    }

    &.skills-page,
    &.career-page,
    &.art-gallery-page {
      width: 100%;
      padding: 20px;
      box-sizing: border-box;

      h1.page-title {
        margin-left: 20px;
        margin-top: 20px;
      }

      .image-box {
        height: 200px;
        max-width: calc(50% - 10px);
      }
    }
  }
}
